import Api from "./Api";

// export const GetAcquisitionReport = async (payload) =>
//   Api().post(`report/acquisitionReport`, payload);

// export const GetReportById = async (payload) =>
//   Api().post(`report/getReportById`, payload);

export const GetRightsReport = async (payload) =>
  Api().post(`report/rights`, payload);

export const GetMovieOverview = async (payload) =>
  Api().post("report/movieOverview", payload);

export const GetContractOverview = async (payload) =>
  Api().post("report/contractOverview", payload);

export const GetRoyaltyReportOverview = async (payload) =>
  Api().post("report/royaltyReportOverview", payload);

export const GetSalesAvailabilitiesReport = async (payload) =>
  Api().post("report/salesAvailabilitiesReport", payload);
export const GetSalesAvailableReport = async (payload) =>
  Api().post("report/getSalesAvailableReport", payload);
export const SaveReportConfig = async (payload) =>
  Api().post("report/saveReportConfig", payload);
